import { createTheme } from "@mui/material/styles";
import { pxToLineHeight } from "./utils/screen";

export const colors = {
  white: { main: "#EBECF0" },
  black: { main: "#363636" },
  grey: { main: "#BBBCBC", dark: "#8B8C8C" },
  red: { main: "#D50032", dark: "#A40228" },
  blue: { main: "#1B365D", dark: "#0E2444", light: "rgba(27, 54, 93, 0.2)" },
  purple: { main: "#A2195B", dark: "#75093D" },
  orange: { main: "#EB5F0A", dark: "#B14707", next: "#F49F6C" },
  orangeLight: { main: "#F28E00", dark: "#CA7701" },
  yellow: { main: "#F8BD00", dark: "#D09F00" },
  green: { main: "#5AB574", dark: "#4A8A5C" },
  tourquesa: { main: "#00998A", dark: "#017065" },
};

export const theme = createTheme({
  palette: {
    corporate: {
      lighter: "rgba(244, 159, 108, 0.06)",
      light: "rgba(244, 159, 108, 0.12)",
      main: "rgba(244, 159, 108, 1)",
      dark: "rgba(244, 108, 29, 1)",
      darker: "rgba(238, 126, 57, 1)",
      gradientStart: "rgba(244, 159, 108, 0.34)",
      gradientEnd: "rgba(244, 159, 108, 0)",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    primary: colors.blue,
    secondary: colors.red,
    neutral: {
      lightest: "rgba(255, 255, 255, 1)",
      lighter: "rgba(241, 241, 243, 1)",
      light: "#EDEEEE",
      contrastText: "rgba(255, 255, 255, 0.87)",
      ...colors.grey,
    },
    info: colors.tourquesa,
    success: {
      ...colors.green,
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    warning: {
      light: colors.yellow.main,
      main: colors.orangeLight.main,
      dark: "#EB5F0A",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
    error: colors.purple,
    inherit: {
      main: "#FFFFFF",
      dark: "rgb(225, 227, 231)",
      // light: colors.white.main,
    },
    blur: {
      main: "rgba(255, 255, 255, 0.2)",
      contrastText: "rgba(255, 255, 255)",
    },
    text: {
      primary: colors.blue.main,
      light: "rgba(14, 35, 64, 0.6)",
    },
  },
  spacing: (factor) => `${12 * factor}px`,
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: "\"Inter\", sans-serif",
    h1: {
      fontWeight: 700,
      color: colors.blue.main,
    },
    h2: {
      fontWeight: 700,
      color: colors.blue.main,
      // lineHeight: pxToLineHeight(22, 48),
    },
    h3: {
      fontWeight: 700,
      color: colors.blue.main,
    },
    h4: {
      fontWeight: 700,
      color: colors.blue.main,
    },
    h5: {
      fontSize: 16,
      fontWeight: 700,
      color: colors.blue.main,
    },
    h6: {},
    text: {
      fontWeight: 400,
      color: colors.blue.main,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.blue.main,
      lineHeight: pxToLineHeight(22, 16),
    },
    // subtitle: {
    //   fontSize: 18,
    //   fontWeight: 400,
    //   color: colors.blue.main,
    //   fontFamily: '"Inter"',
    // },
    small: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.blue.main,
      lineHeight: pxToLineHeight(22, 14),
    },
    comment: {
      fontSize: 12,
      fontWeight: 400,
      color: colors.blue.main,
      lineHeight: 1,
    },
    body2: {},
    button: {
      textTransform: "none",
      lineHeight: 1,
    },
    caption: {},
    overline: {},
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          overflowY: "auto !important",
        },
      },
    },
  },
  // components: {
  //   MuiInputLabel: {
  //     defaultProps: {
  //       sx: {
  //         fontSize: 16,
  //       },
  //     },
  //   },
  //   MuiInputBase: {
  //     defaultProps: {
  //       sx: {
  //         height: "48px",
  //         fontSize: "16px",
  //       },
  //     },
  //   },
  // },
});

// Responsive fonts and components

theme.typography.h1 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: 42,
    lineHeight: 1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 58,
  },
};

theme.typography.h2 = {
  lineHeight: "1 !important",
  [theme.breakpoints.down("sm")]: {
    fontSize: 36,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 42,
  },
};

theme.typography.h3 = {
  [theme.breakpoints.down("sm")]: {
    fontSize: 24,
    lineHeight: 1.1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 28,
    lineHeight: 1,
  },
};

theme.typography.h4 = {
  lineHeight: "1 !important",
  [theme.breakpoints.down("sm")]: {
    fontSize: 20,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 24,
  },
};

theme.typography.text = {
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    lineHeight: 1,
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: 18,
  },
};

theme.components.MuiButton = {
  styleOverrides: {
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: "12px 16px",
        height: "40px",
      },
      [theme.breakpoints.up("sm")]: {
        padding: "4px 16px",
      },
      "> p, * svg": { fontWeight: 500 },
    },
  },
};

theme.components.MuiTable = {
  styleOverrides: {
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        overflowX: "auto",
      },
    },
  },
};

theme.components.MuiChip = {
  styleOverrides: {
    root: {
      borderRadius: "5px",
    },
  },
};
