import React from "react";
import styled from "@emotion/styled";
import { useLocalization } from "gatsby-theme-i18n";
import { Box, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import worlImage from "../../../../images/world.png";
import blog from "../../../../cms/blog/blog";
import { theme } from "../../../../theme";
import SEO from "../../../../components/seo";

const styles = {
  hongkong: {
    "animation-delay": "0",
    top: " 47.5%",
    left: "85%",
  },
  bogota: {
    "animation-delay": "0",
    top: "59.5%",
    left: "24%",
  },
  zurich: {
    "animation-delay": "0",
    top: "36.5%",
    left: "50.5%",
  },
  dubai: {
    "animation-delay": "0",
    top: "49.5%",
    left: "66%",
  },
  stockholm: {
    "animation-delay": "0",
    top: "29.5%",
    left: "52%",
  },
  madrid: {
    "animation-delay": "3s",
    top: "41%",
    left: "46.5%",
  },
  london: {
    "animation-delay": "3s",
    top: "34%",
    left: "47.5%",
  },
  milan: {
    "animation-delay": "3s",
    top: "38%",
    left: "51%",
  },
  saopaulo: {
    "animation-delay": "3s",
    top: "73.5%",
    left: "32.5%",
  },
  warsaw: {
    "animation-delay": "3s",
    top: "34%",
    left: "54%",
  },
  santiago: {
    "animation-delay": "6s",
    top: "77.5%",
    left: "25.5%",
  },
  luxembourg: {
    "animation-delay": "6s",
    top: "35%",
    left: "49.5%",
  },
  singapore: {
    "animation-delay": "6s",
    top: "59.5%",
    left: "82%",
  },
  miami: {
    "animation-delay": "6s",
    top: "46%",
    left: "22%",
  },
};

theme.components.MuiCssBaseline = {
  styleOverrides: {
    body: {
      overflow: "hidden",
    },
  },
};

function NewsVerticalWidget() {
  const currentOffices = Object.keys(styles);
  const { locale } = useLocalization();
  const articles = Object.values(blog[locale])
    .filter(({ attributes: { show_in_widget } }) => show_in_widget !== false)
    .slice(0, 4);
  const [currentArticles, setCurrentArticles] = React.useState(articles);
  const [count, setCount] = React.useState(0);
  const timer = () => setCount(count + 1);

  React.useEffect(() => {
    const id = setInterval(() => {
      timer();
      const newItems = count % 2 === 0 ? articles.slice(2, 4) : articles.slice(0, 2);
      setCurrentArticles([...currentArticles, ...newItems]);
    }, 10000);

    return () => {
      clearInterval(id);
    };
  }, [currentArticles]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SEO />
      <Box
        sx={{
          overflow: "hidden",
          minHeight: "auto",
          position: "relative",
          height: "642px",
          width: "288px",
        }}
      >
        <StyledContainer count={count}>
          {currentArticles?.map(({ attributes }, index) => (
            <NewItem key={`${attributes.title}${index}`} url={attributes.header_image.data.attributes.url}>
              <Box>
                <Typography>{attributes.title}</Typography>
              </Box>
            </NewItem>
          ))}
        </StyledContainer>
        <NewsWidgetBox>
          <NewsWidgetMap>
            {/* <Box
              sx={{
                position: "absolute",
                height: "5px",
                width: "5px",
                border: "1px solid white",
              }}
            /> */}
            <TvCityList>
              {currentOffices.map((value) => (
                <StyledOffice name={value} />
              ))}
            </TvCityList>
          </NewsWidgetMap>
        </NewsWidgetBox>
      </Box>
    </ThemeProvider>
  );
}

const NewItem = styled.div`
  height: 216px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: ${({ url }) => `url(${url})`};
  p {
    padding: 0.5em;
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
  }
  div {
    position: absolute;
    bottom: 0;
    padding-top: 5em;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const StyledContainer = styled.div`
  overflowy: auto;
  min-height: auto;
  position: relative;
  max-height: 432px;
  transition: 3s, opacity 3s;
  transform: ${({ count }) => `translateY(-${count * 100}%)`};
`;

const StyledOffice = styled.li`
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 4px !important;
  height: 4px !important;
  border-radius: 100%;
  background: #d40031;
  text-indent: -9999em;
  animation: tvMapCities 9s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  top: ${({ name }) => styles[name]?.top};
  left: ${({ name }) => styles[name]?.left};
  animation-delay: ${({ name }) => styles[name]?.["animation-delay"]};

  @keyframes tvMapCities {
    from {
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    30% {
      opacity: 1;
    }

    35% {
      opacity: 0;
    }

    to {
      opacity: 0;
    }
  }
`;

const TvCityList = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  list-style-type: none;
`;

const NewsWidgetMap = styled.div`
  background-color: #1b365d;
  width: 288px;
  height: 211px;
  position: relative;
  background-image: url(${worlImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  float: left;
  clear: both;
`;

const NewsWidgetBox = styled.div`
  overflow: hidden;
  position: relative;
  width: 288px;
  background: white;
  flex: 1;
`;

export default NewsVerticalWidget;
